<template>
  <div :id="storyViewerComputedId" class="story-viewer-wrapper">
    <div v-show="showWatermark && isDesktopWidth" class="watermark-wrapper">
      <div class="watermark">
        <a href="https://tryreeltok.com/?ref=story-viewer" target="_blank">
          <p style="color: white; font-size: 13px; line-height: 2">Created with </p>
        </a>
      </div>
      <div class="watermark-logo ">
        <a href="https://tryreeltok.com/" target="_blank">
          <img :src="logoImageSrc" alt="ReelTok logo" style="width: 52px; height: 12px">
        </a>
      </div>
    </div>

    <div class="story-viewer">
      <div v-show="isDesktopWidth && !isGetProductsLoading" class="close-button-wrapper">
        <button class="close-button" @click="closeStoryViewerFromCloseButton">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <!-- DESKTOP -->
      <div class="desktop-story-viewer" v-show="isDesktopWidth">
        <div v-if="isGetProductsLoading"></div>
        <div v-else v-for="(scene, index) in scenes" :key="index" style="margin-top: 80px" v-show="index === currentSceneIndex">
          <DesktopStory
            @next-story="nextStory"
            @previous-story="previousStory"
            @video-play="onDesktopVideoPlay"
            @video-pause="onDesktopVideoPause"
            @mute="onDesktopMute"
            @unmute="onDesktopUnmute"
            @goToCart="openCart"
            @closeCart="closeCart"
            @close="closeStoryViewerFromCloseButton"
            :scene="scene"
            :journey="journey"
            :shopify-item="getShopifyItemForScene(scene)"
            :is-playing="isDesktopWidth && pausedSceneIdDesktop !== scene.id && index === currentSceneIndex"
            :is-muted="!isDesktopWidth || isStoriesMutedDesktop || index !== currentSceneIndex"
            :has-controls="scenes.length > 1"
            :is-cart-open="isCartOpen"
          />
        </div>
      </div>

      <!-- MOBILE -->
      <div class="mobile-story-viewer" v-show="!isDesktopWidth">
        <div class="vertical-carousel" :style="{ height: '100dvh' }" ref="carousel" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd" @mousewheel="onMouseWheel">
          <div class="carousel-container" :style="{ transform: 'translateY(' + translateY + 'px)' }">
            <div class="carousel-item" v-for="(scene, index) in scenes" :key="index">
              <div v-if="isGetProductsLoading"></div>
              <MobileStory
                v-else
                @sceneClick="onMobileSceneClick"
                @video-play="onVideoPlay"
                @video-pause="onVideoPause"
                @mute="onMute"
                @unmute="onUnmute"
                @close="closeStoryViewerFromCloseButton"
                @goToPrevScene="onPrevSceneClick"
                @goToNextScene="onNextSceneClick"
                @goToCart="openMobileCart"
                @closeCart="closeMobileCart"
                :is-playing="!isDesktopWidth && pausedSceneIdMobile !== scene.id && index === mobileCurrentIndex"
                :is-muted="isDesktopWidth || isStoriesMutedMobile || index !== mobileCurrentIndex"
                :scene="scene"
                :journey="journey"
                :shopify-item="getShopifyItemForScene(scene)"
                :show-watermark="showWatermark"
                :show-mobile-scene-controls="scenes.length > 1"
                :is-cart-open="isCartOpen"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isGetProductsLoading" class="story-viewer-loader">
      <div class="circle-loader"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import shopifyRepository from '@/api/repositories/storefront';
import DesktopStory from './stories/DesktopStory.vue';
import MobileStory from './stories/MobileStory.vue';
import Hammer from 'hammerjs';
import ShopifyItemMobileStory from '@/components/stories/Shopify/mobile/ShopifyItemMobileStory.vue';

// const verticalSpaceBetweenMobileStories = 91;
const storyViewerSwipeResponsiveness = 3.5;

export default {
  name: 'StoryViewer',
  components: {ShopifyItemMobileStory, DesktopStory, MobileStory },
  props: {
    journey: {
      type: Object,
      required: true
    },
    selectedSceneIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isCartOpen: false,

      isGetProductsLoading: true,
      currentSceneIndex: 0,
      storyViewerId: null,
      storyViewerParentNode: null,
      shopifyProducts: null,
      shopifyCollections: null,
      windowWidth: window.innerWidth,
      pausedSceneIdDesktop: null,
      isStoriesMutedDesktop: false,

      // For mobile swiper
      videoPlayMap: {},
      mobileCurrentIndex: 0,
      startY: 0,
      lastY: 0,
      translateY: 0,
      pausedSceneIdMobile: null,
      isStoriesMutedMobile: false,

      itemHeight: 0,
      logoImageSrc: 'https://d1i2yc776z09uv.cloudfront.net/logo.png',
    }
  },
  computed: {
    ...mapGetters({
      storefrontAccessToken: 'journeys/storefrontAccessToken',
      storeDomain: 'journeys/storeDomain',
      cloudName: 'journeys/cloudName',
      showWatermark: 'journeys/showWatermark',
      productPopupOpened: 'util/productPopupOpened',
      landedDirectToScene: 'util/landedDirectToScene',
    }),
    verticalSpaceBetweenMobileStories() {
      if (this.windowWidth < 590) {
        return 45;
      }

      return 91;
    },
    scenes() {
      return this.journey.scenes;
    },
    storyViewerComputedId() {
      return `story-viewer-${this.storyViewerId}`;
    },
    isDesktopWidth() {
      return this.windowWidth > 860;
    }
  },
  async created() {
    // Cannot autoplay without user interaction unless it's muted.
    // Solves case when reeltok_scene is present in the URL
    if (this.landedDirectToScene) {
      this.onMute();
      this.onDesktopMute();
      this.$store.dispatch('util/setLandedDirectToScene', false);
    }

    this.hideEverything();
    this.currentSceneIndex = this.selectedSceneIndex;
    this.storyViewerId = this.generateRandomString();
    await this.getShopifyItemsForActions();

    this.itemHeight = this.calculateItemHeight();
    this.translateY = -this.mobileCurrentIndex * this.itemHeight;
  },
  mounted() {
    const carouselElement = this.$refs.carousel;
    const hammer = new Hammer(carouselElement);

    hammer.get('pan').set({direction: Hammer.DIRECTION_VERTICAL});
    hammer.on('panstart', this.onTouchStart);
    hammer.on('panmove', this.onTouchMove);
    hammer.on('panend', this.onTouchEnd);

    this.mobileCurrentIndex = this.selectedSceneIndex;
    this.translateY = -this.mobileCurrentIndex * this.itemHeight;

    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    this.$store.dispatch('videos/setPlayingVideoPublicId', null);
  },
  methods: {
    hideEverything() {
      const journeyUuid = this.journey.uuid;
      const styleElement = document.createElement("style");
      styleElement.setAttribute("id", 'style-' + journeyUuid);
      styleElement.textContent = `
        html, body {
          min-height: 100vh !important;
          width: 100% !important;
          overflow: hidden !important;
          max-height: 100vh !important;
          background: rgba(26, 26, 26) !important;
          position: relative !important;
        }

        #journey-${journeyUuid} {
          visibility: visible !important;
        }

        .reel-tok-viewer :not(#journey-${journeyUuid}, #journey-${journeyUuid} *) {
          visibility: hidden !important;
        }

        body *:not(#journey-${journeyUuid}, #journey-${journeyUuid} *) {
          transform: none !important;
          z-index: 0 !important;
          transform-style: unset !important;
          transition: none !important;
          visibility: hidden !important;
          overflow: visible !important;
          animation: none;
          filter: none !important;
          opacity: 1;
        }

        body *:not(#journey-${journeyUuid}, #journey-${journeyUuid} *) iframe {
          display: none !important;
        }
      `;
      document.head.appendChild(styleElement);
    },
    calculateItemHeight() {
      return this.$refs.carousel.querySelector('.carousel-item').clientHeight;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.itemHeight = this.calculateItemHeight();
    },
    onMobileSceneClick(scene) {
      console.log('change scene: ', scene);
      // this.$emit('story-changed', { sceneUuid: scene.uuid });
    },
    onVideoPlay(sceneId) {
      if (sceneId === this.pausedSceneIdMobile) {
        this.pausedSceneIdMobile = null;
      }
    },
    onVideoPause(sceneId) {
      this.pausedSceneIdMobile = sceneId;
    },
    onMute() {
      this.isStoriesMutedMobile = true;
    },
    onUnmute() {
      this.isStoriesMutedMobile = false;
    },
    onDesktopVideoPlay(sceneId) {
      if (sceneId === this.pausedSceneIdDesktop) {
        this.pausedSceneIdDesktop = null;
      }
    },
    onDesktopVideoPause(sceneId) {
      this.pausedSceneIdDesktop = sceneId;
    },
    onDesktopMute() {
      this.isStoriesMutedDesktop = true;
    },
    onDesktopUnmute() {
      this.isStoriesMutedDesktop = false;
    },
    onTouchStart(event) {
      if (this.productPopupOpened) {
        return;
      }

      if (event.target.tagName.toLowerCase() === 'video') {
        try {
          this.startY = event.touches[0].clientY;
          this.lastY = this.startY;
        } catch (e) {
          // console.log(event);
        }
      }
    },
    onTouchMove(event) {
      if (this.productPopupOpened) {
        return;
      }

      if (event.target.tagName.toLowerCase() === 'video') {
        try {
          const deltaY = event.touches[0].clientY - this.lastY;
          this.translateY += (deltaY * storyViewerSwipeResponsiveness);
          this.lastY = event.touches[0].clientY;
        } catch (e) {
          // console.log(event);
        }
      }
    },
    onTouchEnd(event) {
      if (this.productPopupOpened) {
        return;
      }

      if (event.target.tagName.toLowerCase() === 'video') {
        // Calculate the index based on the translateY value
        let newIndex = Math.round(-this.translateY / (this.itemHeight));
        // Limit the index within the range of videos
        newIndex = Math.max(0, Math.min(newIndex, this.scenes.length - 1));

        if (this.mobileCurrentIndex !== newIndex) {
          this.$emit('story-changed', { sceneUuid: this.scenes[newIndex].uuid });
        }

        this.mobileCurrentIndex = newIndex;

        // this.mobileCurrentIndex = newIndex;
        // Update translateY to show the current video
        this.translateY = -this.mobileCurrentIndex * this.itemHeight;
      }
    },
    onMouseWheel(e) {
      if (e.target.tagName.toLowerCase() !== 'video') {
        return;
      }

      if (e.deltaY > 0) {
        // Scrolling down, go to next video
        this.goToNextVideo();
      } else {
        // Scrolling up, go to previous video (if any)
        if (this.mobileCurrentIndex > 0) {
          this.mobileCurrentIndex--;
          this.translateY = -this.mobileCurrentIndex * this.itemHeight;
          this.$emit('story-changed', { sceneUuid: this.scenes[this.mobileCurrentIndex].uuid });
        }
      }
    },
    goToNextVideo() {
      if (this.mobileCurrentIndex < this.scenes.length - 1) {
        this.mobileCurrentIndex++;
        this.translateY = -this.mobileCurrentIndex * this.itemHeight;
        this.pausedSceneIdMobile = null;
        this.$emit('story-changed', { sceneUuid: this.scenes[this.mobileCurrentIndex].uuid });
      }
    },
    getStyleForIndex(index) {
      const marginTop = `${this.verticalSpaceBetweenMobileStories}px`;

      if (index === this.mobileCurrentIndex) {
        return {opacity: '1', marginTop: marginTop};
      }

      if (index - 1 === this.mobileCurrentIndex || index + 1 === this.mobileCurrentIndex) {
        return {opacity: '0.15', marginTop: marginTop};
      }

      return {opacity: '0', marginTop: marginTop};
    },

    nextStory() {
      this.pausedSceneIdDesktop = null;

      if (this.currentSceneIndex < this.scenes.length - 1) {
        this.currentSceneIndex++;
      } else {
        this.currentSceneIndex = 0;
      }

      this.$emit('story-changed', { sceneUuid: this.scenes[this.currentSceneIndex].uuid });
    },
    previousStory() {
      this.pausedSceneIdDesktop = null;

      if (this.currentSceneIndex > 0) {
        this.currentSceneIndex--;
      } else {
        this.currentSceneIndex = this.scenes.length - 1;
      }

      this.$emit('story-changed', { sceneUuid: this.scenes[this.currentSceneIndex].uuid });
    },
    generateRandomString() {
      return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    },
    async getShopifyItemsForActions() {
      try {
        this.isGetProductsLoading = true;

        const shopifyProductIds = this.journey.scenes.map(scene => scene.action).filter(action => action.type !== 'BUTTON' && action.config.type === 'product').map(action => action.config.id);
        const shopifyCollectionIds = this.journey.scenes.map(scene => scene.action).filter(action => action.type !== 'BUTTON' && action.config.type === 'collection').map(action => action.config.id);

        const uniqueShopifyProductIds = [...new Set(shopifyProductIds)];
        const uniqueShopifyCollectionIds = [...new Set(shopifyCollectionIds)];

        const productsPromise = shopifyRepository.getProductsByIds(this.storeDomain, this.storefrontAccessToken, uniqueShopifyProductIds);
        const collectionsPromise = shopifyRepository.getCollectionsByIds(this.storeDomain, this.storefrontAccessToken, uniqueShopifyCollectionIds);

        const [productsResponse, collectionsResponse] = await Promise.all([productsPromise, collectionsPromise]);

        this.shopifyProducts = productsResponse.data.data.nodes;
        this.shopifyCollections = collectionsResponse.data.data.nodes;
      } catch (e) {
        console.error(e);
      } finally {
        this.isGetProductsLoading = false;
      }
    },
    // Turns off story viewer when clicking on backdrop
    // async closeStoryViewer(e) {
    //   const classList = e.target.classList ?? [];
    //
    //   if (
    //     e.target.id === this.storyViewerComputedId
    //     || classList.contains('story-viewer-wrapper')
    //     || classList.contains('story')
    //     || classList.contains('scene-controls')
    //   ) {
    //     document.getElementById('style-' + this.journey.uuid).remove();
    //     this.$emit('close');
    //   }
    // },
    closeStoryViewerFromCloseButton() {
      document.getElementById('style-' + this.journey.uuid).remove();
      this.$emit('close');
    },
    getShopifyItemForScene(scene) {
      if (scene.action.type === 'BUTTON') {
        return {};
      }

      const type = scene.action.config.type;

      if (type === 'product') {
        return this.getShopifyProductForScene(scene);
      }

      if (type === 'collection') {
        return this.getShopifyCollectionForScene(scene);
      }

      return {};
    },
    getShopifyProductForScene(scene) {
      if (!this.shopifyProducts) {
        return {
          isLoading: true,
          title: '',
          description: '',
          featuredImage: '',
          variants: false,
          handle: '',
          options: [],
        };
      }

      return this.shopifyProducts.find(shopifyProduct => shopifyProduct.id === scene.action.config.id);
    },
    getShopifyCollectionForScene(scene) {
      if (!this.shopifyCollections) {
        return {
          isLoading: true,
          title: '',
          description: '',
          featuredImage: '',
          handle: '',
        };
      }

      return this.shopifyCollections.find(shopifyCollection => shopifyCollection.id === scene.action.config.id);
    },
    onPrevSceneClick() {
      const currentIndex = this.mobileCurrentIndex;

      if (currentIndex === 0) {
        this.mobileCurrentIndex = this.scenes.length - 1;
      } else {
        this.mobileCurrentIndex = currentIndex - 1;
      }

      this.pausedSceneIdMobile = null;
      this.translateY = -this.mobileCurrentIndex * this.itemHeight;
      this.$emit('story-changed', { sceneUuid: this.scenes[this.mobileCurrentIndex].uuid });
    },
    onNextSceneClick() {
      const currentIndex = this.mobileCurrentIndex;

      if (currentIndex === this.scenes.length - 1) {
        this.mobileCurrentIndex = 0;
      } else {
        this.mobileCurrentIndex = currentIndex + 1;
      }

      this.pausedSceneIdMobile = null;
      this.translateY = -this.mobileCurrentIndex * this.itemHeight;
      this.$emit('story-changed', { sceneUuid: this.scenes[this.mobileCurrentIndex].uuid });
    },
    openCart() {
      this.isCartOpen = true;
      this.$store.dispatch('cart/setJourneyAndScene', {
        journeyUuid: this.journey.uuid,
        sceneUuid: this.scenes[this.currentSceneIndex].uuid
      });
    },
    closeCart() {
      this.isCartOpen = false;
    },
    openMobileCart() {
      this.isCartOpen = true;

      this.onVideoPause(this.scenes[this.mobileCurrentIndex].id);

      this.$store.dispatch('cart/setJourneyAndScene', {
        journeyUuid: this.journey.uuid,
        sceneUuid: this.scenes[this.mobileCurrentIndex].uuid
      });
    },
    closeMobileCart() {
      this.onVideoPlay(this.scenes[this.mobileCurrentIndex].id);
      this.closeCart();
    }
  },
}
</script>
