<template>
  <Portal class="portal" :id="'windowed-story-viewer-' + journey.uuid" :key="portalKey">
    <div
      class="windowed-story-viewer-wrapper"
      :style="{ width: `${windowedStoryViewerWidth}`, height: `${windowedStoryViewerHeight}`, ...positionStyle }"
    >
      <div class="mobile-story-viewer">
        <div class="vertical-carousel" ref="carousel" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd" @mousewheel="onMouseWheel">
          <div class="carousel-container" :style="{ transform: 'translateY(' + translateY + 'px)' }">
            <div class="carousel-item" v-for="(scene, index) in scenes" :key="index">
              <div v-if="isGetProductsLoading"></div>

              <MobileStory
                v-else
                @video-play="onVideoPlay"
                @video-pause="onVideoPause"
                @mute="onMute"
                @unmute="onUnmute"
                @close="closeStoryViewerFromCloseButton"
                @goToPrevScene="onPrevSceneClick"
                @goToNextScene="onNextSceneClick"
                @goToCart="openMobileCart"
                @closeCart="closeMobileCart"
                :is-playing="pausedSceneIdMobile !== scene.id && index === mobileCurrentIndex"
                :is-muted="isStoriesMutedMobile || index !== mobileCurrentIndex"
                :scene="scene"
                :journey="journey"
                :show-watermark="showWatermark"
                :show-mobile-scene-controls="true"
                :is-cart-open="isCartOpen"
                :shopify-item="getShopifyItemForScene(scene)"
                :is-window-mode="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Portal>
</template>

<script>
import {mapGetters} from 'vuex';
import Hammer from 'hammerjs';
import MobileStory from '@/components/stories/MobileStory.vue';
import shopifyRepository from '@/api/repositories/storefront';
import Portal from '@/components/Portal.vue';

const defaultWindowOffset = 40;
const defaultWindowWidth = 310;
const defaultWindowHeight = 500;

const storyViewerSwipeResponsiveness = 3.5;

export default {
  name: 'WindowedStoryViewer',
  components: {Portal, MobileStory},
  props: {
    portalKey: {
      type: Number,
      required: true
    },
    journey: {
      type: Object,
      required: true
    },
    selectedSceneIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isEverythingHidden: false,
      isCartOpen: false,
      windowedStoryViewerId: null,
      windowedStoryViewerParentNode: null,
      currentSceneIndex: 0,

      isGetProductsLoading: true,
      shopifyProducts: null,
      shopifyCollections: null,
      windowWidth: window.innerWidth,
      carousel: null,

      // For mobile swiper
      videoPlayMap: {},
      mobileCurrentIndex: 0,
      startY: 0,
      lastY: 0,
      translateY: 0,
      pausedSceneIdMobile: null,
      isStoriesMutedMobile: false,
      itemHeight: 0,
      logoImageSrc: 'https://d1i2yc776z09uv.cloudfront.net/logo.png'
    };
  },
  computed: {
    ...mapGetters({
      storefrontAccessToken: 'journeys/storefrontAccessToken',
      storeDomain: 'journeys/storeDomain',
      shadowRoot: 'util/shadowRoot',
      cloudName: 'journeys/cloudName',
      showWatermark: 'journeys/showWatermark',
      productPopupOpened: 'util/productPopupOpened',
      landedDirectToScene: 'util/landedDirectToScene',
    }),
    isMobileWidth() {
      // return this.windowWidth < 501;
      return this.windowWidth < 426;
    },
    scenes() {
      return this.journey.scenes;
    },
    hasJourneyConfig() {
      return this.journey.config && Object.keys(this.journey.config).length > 0;
    },
    windowedStoryViewerWidth() {
      if (this.isMobileWidth) {
        return '100%';
      }

      let windowWidth = defaultWindowWidth;

      if (this.hasJourneyConfig) {
        windowWidth = this.journey.config.window_width || defaultWindowWidth;
      }

      return windowWidth + 'px';
    },
    windowedStoryViewerHeight() {
      if (this.isMobileWidth) {
        // return '100dvh';
        return '100%';
      }

      let windowHeight = defaultWindowHeight;

      if (this.hasJourneyConfig) {
        windowHeight = this.journey.config.window_height || defaultWindowHeight;
      }

      return windowHeight + 'px';
    },
    positionStyle() {
      if (this.isMobileWidth) {
        return {
          top: 0,
          right: 0,
          position: 'fixed',
        };
      }

      const windowPosition = this.windowPosition;
      const offset = this.windowSpacing || defaultWindowOffset;

      if (windowPosition === 'TOP_RIGHT') {
        return {
          top: `${offset}px`,
          right: `${offset}px`
        }
      }

      if (windowPosition === 'TOP_LEFT') {
        return {
          top: `${offset}px`,
          left: `${offset}px`
        }
      }

      if (windowPosition === 'BOTTOM_RIGHT') {
        return {
          bottom: `${offset}px`,
          right: `${offset}px`
        }
      }

      if (windowPosition === 'BOTTOM_LEFT') {
        return {
          bottom: `${offset}px`,
          left: `${offset}px`
        }
      }

      return {}
    },
    windowPosition() {
      return this.journey.config.window_position || 'BOTTOM_RIGHT';
    },
    windowSpacing() {
      if (!this.hasJourneyConfig) {
        return null;
      }

      return this.journey.config.window_spacing;
    },
  },
  async created() {
    if (this.landedDirectToScene) {
      this.onMute();
      this.$store.dispatch('util/setLandedDirectToScene', false);
    }

    await this.getShopifyItemsForActions();
    this.currentSceneIndex = this.selectedSceneIndex;
    this.itemHeight = this.calculateItemHeight();
    this.translateY = -this.mobileCurrentIndex * this.itemHeight;

    if (this.isMobileWidth) {
      this.isEverythingHidden = true;
      this.addStylesThatHideEverythingToDom();
    }
  },
  mounted() {
    this.carouselElement = this.$refs.carousel;

    const hammer = new Hammer(this.carouselElement);
    hammer.get('pan').set({direction: Hammer.DIRECTION_VERTICAL});
    hammer.on('panstart', this.onTouchStart);
    hammer.on('panmove', this.onTouchMove);
    hammer.on('panend', this.onTouchEnd);

    this.mobileCurrentIndex = this.selectedSceneIndex;
    this.translateY = -this.mobileCurrentIndex * this.itemHeight;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    addStylesThatHideEverythingToDom() {
      const journeyUuid = this.journey.uuid;
      const styleElement = document.createElement("style");
      styleElement.setAttribute("id", 'style-' + journeyUuid);
      styleElement.textContent = `
        html, body {
          min-height: 100vh !important;
          width: 100% !important;
          overflow: hidden !important;
          max-height: 100vh !important;
          background: rgba(26, 26, 26) !important;
          position: relative !important;
        }

        #windowed-story-viewer-${journeyUuid},
        #journey-${journeyUuid} {
          visibility: visible !important;
        }

        .reel-tok-viewer :not(
          #windowed-story-viewer-${journeyUuid},
          #windowed-story-viewer-${journeyUuid} *
        ) {
          visibility: hidden !important;
        }

        body *:not(
          #windowed-story-viewer-${journeyUuid},
          #windowed-story-viewer-${journeyUuid} *
        ) {
          transform: none !important;
          z-index: 0 !important;
          transform-style: unset !important;
          transition: none !important;
          visibility: hidden !important;
          overflow: visible !important;
          animation: none;
          filter: none !important;
          opacity: 1;
        }

        body *:not(
          #windowed-story-viewer-${journeyUuid},
          #windowed-story-viewer-${journeyUuid} *
        ) iframe {
          display: none !important;
        }
      `;
      document.head.appendChild(styleElement);
    },
    removeStylesThatHideEverythingFromDom() {
      const style = document.getElementById('style-' + this.journey.uuid);

      if (style) {
        style.remove();
      }
    },
    onTouchStart(event) {
      if (this.productPopupOpened) {
        return;
      }

      if (event.target.tagName.toLowerCase() === 'video') {
        try {
          this.startY = event.touches[0].clientY;
          this.lastY = this.startY;
        } catch (e) {
          // console.log(event);
        }
      }
    },
    onTouchMove(event) {
      if (this.productPopupOpened) {
        return;
      }

      if (event.target.tagName.toLowerCase() === 'video') {
        try {
          const deltaY = event.touches[0].clientY - this.lastY;
          this.translateY += (deltaY * storyViewerSwipeResponsiveness);
          this.lastY = event.touches[0].clientY;
        } catch (e) {
          // console.log(event);
        }
      }
    },
    onTouchEnd(event) {
      if (this.productPopupOpened) {
        return;
      }

      if (event.target.tagName.toLowerCase() === 'video') {
        // Calculate the index based on the translateY value
        let newIndex = Math.round(-this.translateY / (this.itemHeight));
        // Limit the index within the range of videos
        newIndex = Math.max(0, Math.min(newIndex, this.scenes.length - 1));

        if (this.mobileCurrentIndex !== newIndex) {
          this.$emit('story-changed', { sceneUuid: this.scenes[newIndex].uuid });
        }

        this.mobileCurrentIndex = newIndex;
        // Update translateY to show the current video
        this.translateY = -this.mobileCurrentIndex * this.itemHeight;
      }
    },
    onMouseWheel(e) {
      if (e.target.tagName.toLowerCase() !== 'video') {
        return;
      }

      if (e.deltaY > 0) {
        // Scrolling down, go to next video
        this.goToNextVideo();
      } else {
        // Scrolling up, go to previous video (if any)
        if (this.mobileCurrentIndex > 0) {
          this.mobileCurrentIndex--;
          this.translateY = -this.mobileCurrentIndex * this.itemHeight;
          this.$emit('story-changed', { sceneUuid: this.scenes[this.mobileCurrentIndex].uuid });
        }
      }
    },
    goToNextVideo() {
      if (this.mobileCurrentIndex < this.scenes.length - 1) {
        this.mobileCurrentIndex++;
        this.translateY = -this.mobileCurrentIndex * this.itemHeight;
        this.pausedSceneIdMobile = null;
        this.$emit('story-changed', { sceneUuid: this.scenes[this.mobileCurrentIndex].uuid });
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.itemHeight = this.calculateItemHeight();
    },
    calculateItemHeight() {
      return this.carouselElement.querySelector('.carousel-item').clientHeight;
    },
    nextStory() {
      this.pausedSceneIdDesktop = null;

      if (this.currentSceneIndex < this.scenes.length - 1) {
        this.currentSceneIndex++;
      } else {
        this.currentSceneIndex = 0;
      }

      this.$emit('story-changed', { sceneUuid: this.scenes[this.currentSceneIndex].uuid });
    },
    previousStory() {
      this.pausedSceneIdDesktop = null;

      if (this.currentSceneIndex > 0) {
        this.currentSceneIndex--;
      } else {
        this.currentSceneIndex = this.scenes.length - 1;
      }

      this.$emit('story-changed', { sceneUuid: this.scenes[this.currentSceneIndex].uuid });
    },
    onVideoPlay(sceneId) {
      if (sceneId === this.pausedSceneIdMobile) {
        this.pausedSceneIdMobile = null;
      }
    },
    onVideoPause(sceneId) {
      this.pausedSceneIdMobile = sceneId;
    },
    onMute() {
      this.isStoriesMutedMobile = true;
    },
    onUnmute() {
      this.isStoriesMutedMobile = false;
    },
    async closeStoryViewerFromCloseButton() {
      if (this.isMobileWidth) {
        this.removeStylesThatHideEverythingFromDom();
      }

      this.$emit('close');
    },
    onPrevSceneClick() {
      const currentIndex = this.mobileCurrentIndex;

      if (currentIndex === 0) {
        this.mobileCurrentIndex = this.scenes.length - 1;
      } else {
        this.mobileCurrentIndex = currentIndex - 1;
      }

      this.pausedSceneIdMobile = null;
      this.translateY = -this.mobileCurrentIndex * this.itemHeight;
      this.$emit('story-changed', { sceneUuid: this.scenes[this.mobileCurrentIndex].uuid });
    },
    onNextSceneClick() {
      const currentIndex = this.mobileCurrentIndex;

      if (currentIndex === this.scenes.length - 1) {
        this.mobileCurrentIndex = 0;
      } else {
        this.mobileCurrentIndex = currentIndex + 1;
      }

      this.pausedSceneIdMobile = null;
      this.translateY = -this.mobileCurrentIndex * this.itemHeight;
      this.$emit('story-changed', { sceneUuid: this.scenes[this.mobileCurrentIndex].uuid });
    },
    openMobileCart() {
      this.isCartOpen = true;

      this.onVideoPause(this.scenes[this.mobileCurrentIndex].id);

      this.$store.dispatch('cart/setJourneyAndScene', {
        journeyUuid: this.journey.uuid,
        sceneUuid: this.scenes[this.mobileCurrentIndex].uuid
      });
    },
    closeMobileCart() {
      this.onVideoPlay(this.scenes[this.mobileCurrentIndex].id);
      this.closeCart();
    },
    closeCart() {
      this.isCartOpen = false;
    },
    getShopifyItemForScene(scene) {
      if (scene.action.type === 'BUTTON') {
        return {};
      }

      const type = scene.action.config.type;

      if (type === 'product') {
        return this.getShopifyProductForScene(scene);
      }

      if (type === 'collection') {
        return this.getShopifyCollectionForScene(scene);
      }

      return {};
    },
    getShopifyProductForScene(scene) {
      if (!this.shopifyProducts) {
        return {
          isLoading: true,
          title: '',
          description: '',
          featuredImage: '',
          variants: false,
          handle: '',
          options: [],
        };
      }

      return this.shopifyProducts.find(shopifyProduct => shopifyProduct.id === scene.action.config.id);
    },
    getShopifyCollectionForScene(scene) {
      if (!this.shopifyCollections) {
        return {
          isLoading: true,
          title: '',
          description: '',
          featuredImage: '',
          handle: '',
        };
      }

      return this.shopifyCollections.find(shopifyCollection => shopifyCollection.id === scene.action.config.id);
    },
    async getShopifyItemsForActions() {
      try {
        this.isGetProductsLoading = true;

        const shopifyProductIds = this.journey.scenes.map(scene => scene.action).filter(action => action.type !== 'BUTTON' && action.config.type === 'product').map(action => action.config.id);
        const shopifyCollectionIds = this.journey.scenes.map(scene => scene.action).filter(action => action.type !== 'BUTTON' && action.config.type === 'collection').map(action => action.config.id);

        const uniqueShopifyProductIds = [...new Set(shopifyProductIds)];
        const uniqueShopifyCollectionIds = [...new Set(shopifyCollectionIds)];

        const productsPromise = shopifyRepository.getProductsByIds(this.storeDomain, this.storefrontAccessToken, uniqueShopifyProductIds);
        const collectionsPromise = shopifyRepository.getCollectionsByIds(this.storeDomain, this.storefrontAccessToken, uniqueShopifyCollectionIds);

        const [productsResponse, collectionsResponse] = await Promise.all([productsPromise, collectionsPromise]);

        this.shopifyProducts = productsResponse.data.data.nodes;
        this.shopifyCollections = collectionsResponse.data.data.nodes;
      } catch (e) {
        console.error(e);
      } finally {
        this.isGetProductsLoading = false;
      }
    },
  },
  watch: {
    selectedSceneIndex(newIndex) {
      this.mobileCurrentIndex = newIndex;
      this.translateY = -this.mobileCurrentIndex * this.itemHeight;
    },
    isMobileWidth(newIsMobileWidth) {
      if (newIsMobileWidth) {
        if (!this.isEverythingHidden) {
          console.log('DDDD', 'addStylesThatHideEverythingToDom');
          this.addStylesThatHideEverythingToDom();
          this.isEverythingHidden = true;
        }

        return;
      }

      if (this.isEverythingHidden) {
        console.log('DDDD', 'removeStylesThatHideEverythingFromDom');
        this.removeStylesThatHideEverythingFromDom();
        this.isEverythingHidden = false;
      }
    },
  },
}
</script>