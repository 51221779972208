<template>
  <div class="shopify-collection-story">
    <div v-if="selectedProduct === null" class="collection-wrapper">
      <div style="color: black; padding-left: 20px; padding-right: 20px; margin-bottom: 20px">
        <div class="collection-header">
          <div style="padding-top: 8px; padding-bottom: 8px;">
            <p class="collection-subtitle">{{ collectionProducts.length }} products</p>
            <h3 class="collection-title">{{ collection.title }}</h3>
          </div>
        </div>

        <div class="collections">
          <div v-for="product in collectionProducts" :key="product.id" style="width: 150px; margin-bottom: 24px;">
            <div class="collection-img-wrapper">
              <img @click="selectProduct(product)" class="collection-image" :src="product.featuredImage.src" :alt="product.featuredImage.altText">
            </div>

            <h4 @click="selectProduct(product)" class="product-title">{{ product.title }}</h4>

            <div style="display: flex">
              <div style="display: flex; flex-direction: column; justify-content: center">
                <p style="font-size: 12px; color: rgb(17, 24, 39)">{{ parseFloat(product.priceRange.minVariantPrice.amount).toFixed(2) }} {{ product.priceRange.minVariantPrice.currencyCode }}</p>
              </div>

              <div style="margin-left: 4px; display: flex; flex-direction: column; justify-content: center;" v-if="getLargestCompareAtPrice(product.variants.nodes, product.priceRange.minVariantPrice.amount, product.priceRange.minVariantPrice.currencyCode)">
                <p style="color: rgb(107, 114, 128); font-size: 12px; text-decoration: line-through;">{{ getLargestCompareAtPrice(product.variants.nodes, product.priceRange.minVariantPrice.amount, product.priceRange.minVariantPrice.currencyCode) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ShopifyProductStory
      v-else
      @backClick="selectProduct(null)"
      :with-back-arrow="true"
      :scene="scene"
      :journey="journey"
      :product="selectedProduct"
    />
  </div>
</template>

<script>
import ShopifyProductStory from '@/components/stories/Shopify/desktop/ShopifyProductStory.vue';
import {getLargestCompareAtPrice} from '@/mixins/getLargestCompareAtPrice';

export default {
  name: 'ShopifyCollectionStory',
  components: {ShopifyProductStory},
  mixins: [getLargestCompareAtPrice],
  data() {
    return {
      selectedProduct: null,
    }
  },
  props: {
    scene: {
      type: Object,
      required: true
    },
    journey: {
      type: Object,
      required: true
    },
    collection: {
      type: Object,
      required: true
    },
  },
  computed: {
    collectionProducts() {
      return this.collection.products.nodes;
    }
  },
  methods: {
    selectProduct(product) {
      this.selectedProduct = product;
    },
  },
}
</script>
