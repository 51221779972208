<template>
  <div class="story-format" :style="{ minWidth: `calc((${storySize}px + ${storySpacing}px) * ${journey.scenes.length})`, marginTop: '16px', marginBottom: '16px' }">
    <div v-for="(scene, sceneIndex) in journey.scenes" :key="scene.id" class="scene" :style="{ marginLeft: sceneIndex === 0 ? `${storySpacing}px` : '', marginRight: `${storySpacing}px` }">
      <div
        :class="{ 'video-player-wrapper ': true, 'use-default-border': useDefaultBorder }"
        :style="{ background: useDefaultBorder ? '' : journey.config.border_color, width: `${storySize}px`, height: `${storySize}px` }"
      >
        <div class="simple-video-player-wrapper">
          <SimpleVideoPlayer
            v-if="isAutoplayAtTheSameTimeEnabled || (isAutoplayEnabled && sceneIndex === autoPlayingSceneIndex)"
            class="simple-video-player"
            :style="{ width: `${storySize}px`, height: `${storySize * 1.8}px`, top: `-${storySize * 0.4}px` }"
            :scene="scene"
            :cloudName="cloudName"
            :publicId="scene.video.public_id"
          />

          <div
            v-else
            class="thumbnail" :style="{ width: `${storySize}px`, height: `${storySize}px` }"
            v-html="getThumbnailComponent(scene)"
          >
          </div>

          <div
            class="invisible-button"
            :style="{ width: `${storySize}px`, height: `${storySize}px` }"
            @click="handleActionButtonClick(scene.id, scene.uuid)"
          >
            <span class="invisible"></span>
          </div>
        </div>
      </div>

      <div class="scene-text" v-if="scene.text && scene.text.length > 0" :style="{ width: `${storySize}px` }">
        <small :style="useDefaultSceneTextColor ? 'color: #000000;' : `color: ${journey.config.scene_text_color}`">{{ scene.text }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SimpleVideoPlayer from '@/components/video-players/SimpleVideoPlayer.vue';
import {getThumbnailSrcForScene, getVideoSrcForScene} from '@/helpers/video';

const defaultStorySize = 100;
const defaultStoryMarginRight = 20;

export default {
  name: 'StoryFormat',
  components: { SimpleVideoPlayer},
  computed: {
    ...mapGetters({
      cloudName: 'journeys/cloudName',
      windowWidth: 'util/windowWidth',
    }),
    sceneWidth() {
      if (!this.hasJourneyConfig) {
        return null;
      }
      return this.journey.config.scene_width;
    },
    sceneSpacing() {
      if (!this.hasJourneyConfig) {
        return null;
      }
      return this.journey.config.scene_spacing;
    },
    storySize() {
      if (this.sceneWidth != null) {
        return this.sceneWidth;
      }

      return defaultStorySize;
    },
    storySpacing() {
      if (this.sceneSpacing != null) {
        return this.sceneSpacing;
      }

      return defaultStoryMarginRight;
    },
    hasJourneyConfig() {
      return this.journey.config && Object.keys(this.journey.config).length > 0;
    },
    isAutoplayEnabled() {
      if (!this.hasJourneyConfig) {
        return false;
      }
      return this.journey.config.autoplay;
    },
    useDefaultBorder() {
      if (!this.hasJourneyConfig) {
        return true;
      }
      return !this.journey.config.border_color || this.journey.config.border_color.length === 0;
    },
    useDefaultSceneTextColor() {
      if (!this.hasJourneyConfig) {
        return true;
      }
      return !this.journey.config.scene_text_color || this.journey.config.scene_text_color.length === 0;
    },
    isAutoplayAtTheSameTimeEnabled() {
      if (!this.hasJourneyConfig) {
        return false;
      }

      return this.journey.config.autoplay_at_same_time;
    },
  },
  data() {
    return {
      autoPlayInterval: null,
      autoPlayingSceneIndex: null,
      maxAutoplayLoops: 2, // stop autoplaying after this many loops
      loopCounter: 0,
    }
  },
  props: {
    journey: {
      type: Object,
      required: true
    },
  },
  created() {
    if (this.isAutoplayEnabled && !this.isAutoplayAtTheSameTimeEnabled) {
      this.autoPlayingSceneIndex = 0;

      this.autoPlayInterval = setInterval(() => {
        if (this.autoPlayingSceneIndex === this.journey.scenes.length - 1) {
          this.loopCounter += 1;

          if (this.loopCounter === this.maxAutoplayLoops) {
            this.autoPlayingSceneIndex = null;
            clearInterval(this.autoPlayInterval);
            return;
          }

          this.autoPlayingSceneIndex = 0;
        } else {
          this.autoPlayingSceneIndex++;
        }
      }, 8000);
    }
  },
  destroyed() {
    clearInterval(this.autoPlayInterval);
  },
  methods: {
    getThumbnailComponent(scene) {
      const storySize = Math.round(this.storySize) * 2;
      const thumbnailSrc = getThumbnailSrcForScene(scene, `h_${storySize},w_${storySize}`);
      const style = `width: ${storySize}px; height: ${storySize}px; object-fit: cover;`;

      if (thumbnailSrc) {
        return `
          <div class="image-wrapper">
            <img src="${thumbnailSrc}" alt="Scene" style="${style}">
          </div>
        `;
      }

      const videoSrc = getVideoSrcForScene(scene);

      return `
        <video class="simple-video-player" muted style="${style}">
          <source src="${videoSrc}" />
        </video>
      `;
    },
    handleActionButtonClick(sceneId, sceneUuid) {
      this.$emit('click', {journey: this.journey, sceneId, sceneUuid});
    },
  }
}
</script>
