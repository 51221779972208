export const state = {
  windowWidth: window.innerWidth,
  toast: null,
  shadowRoot: null,
  productPopupOpened: false,
  landedDirectToScene: false, // true when reeltok_scene is present in the url
};

export const getters = {
  windowWidth: state => state.windowWidth,
  toast: state => state.toast,
  isToastVisible: state => state.toast != null,
  shadowRoot: state => state.shadowRoot,
  productPopupOpened: state => state.productPopupOpened,
  landedDirectToScene: state => state.landedDirectToScene,
};

export const mutations = {
  SET_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },
  SET_TOAST(state, toast) {
    state.toast = toast;
  },
  SET_SHADOW_ROOT(state, shadowRoot) {
    state.shadowRoot = shadowRoot;
  },
  SET_PRODUCT_POPUP_OPENED(state, productPopupOpened) {
    state.productPopupOpened = productPopupOpened;
  },
  SET_LANDED_DIRECT_TO_SCENE(state, landedDirectToScene) {
    state.landedDirectToScene = landedDirectToScene;
  }
};

export const actions = {
  setLandedDirectToScene({ commit }, value) {
    commit('SET_LANDED_DIRECT_TO_SCENE', value);
  },

  productPopupOpened({commit}, value) {
    commit('SET_PRODUCT_POPUP_OPENED', value);
  },

  setWindowWidth({commit}, width) {
    commit('SET_WINDOW_WIDTH', width);
  },
  async setSuccessToast({commit}, message) {
    commit('SET_TOAST', {
      type: 'success',
      message: message
    });

    await new Promise(r => setTimeout(r, 2000));
    commit('SET_TOAST', null);
  },
  async dismissToast({commit}) {
    commit('SET_TOAST', null);
  }
}

export const namespaced = true;
