<template>
  <div class="shopify-item-story">
    <div class="prev-story-button-wrapper" v-if="hasControls">
      <!-- Previous Story Button -->
      <button @click="previousStory" class="story-control-button">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 16px; height: 16px">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"/>
        </svg>
      </button>
    </div>

    <div style="display: flex;">
      <div style="width: 388px; height: 690px;">
        <VideoPlayer
          :scene="scene"
          class="video-player-desktop"
          @pause="onVideoPause"
          @play="onVideoPlay"
          @unmute="onUnmute"
          @mute="onMute"
          @goToCart="onGoToCart"
          :is-playing="isPlaying"
          :is-muted="isMuted"
          :cloudName="cloudName"
          :publicId="scene.video.public_id"
          :playerConfig="{
            loop: true,
            muted: true,
            controls: false,
            bigPlayButton: false,
            responsive: true,
            aspectRatio: '9:16',
            fluid: true,
          }"
          :sourceConfig="{}"
          :close-button="false"
        />
      </div>

      <div v-if="isCartOpen">
        <Cart @closeCart="onCartClose" />
      </div>
      <div v-else>
        <ShopifyProductStory
          v-if="isShopifyProduct"
          :scene="scene"
          :journey="journey"
          :product="shopifyItem"
        />

        <ShopifyCollectionStory
          v-else-if="isShopifyCollection"
          :scene="scene"
          :journey="journey"
          :collection="shopifyItem"
        />
      </div>
    </div>

    <!-- Next Story Button -->
    <div class="next-story-button-wrapper" v-if="hasControls">
      <button @click="nextStory" class="story-control-button">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
             stroke="currentColor" style="width: 16px; height: 16px;">
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VideoPlayer from '@/components/VideoPlayer.vue';
import ShopifyProductStory from '@/components/stories/Shopify/desktop/ShopifyProductStory.vue';
import ShopifyCollectionStory from '@/components/stories/Shopify/desktop/ShopifyCollectionStory.vue';
import Cart from '@/components/Cart.vue';

export default {
  name: 'ShopifyItemStory',
  components: {Cart, ShopifyCollectionStory, ShopifyProductStory, VideoPlayer},
  props: {
    scene: {
      type: Object,
      required: true
    },
    journey: {
      type: Object,
      required: true
    },
    shopifyItem: {
      type: Object,
      required: true
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    isMuted: {
      type: Boolean,
      default: false
    },
    hasControls: {
      type: Boolean,
    },
    isCartOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      cloudName: 'journeys/cloudName',
      storefrontAccessToken: 'journeys/storefrontAccessToken',
      storeDomain: 'journeys/storeDomain',
    }),
    isShopifyProduct() {
      return this.scene.action.config.type === 'product';
    },
    isShopifyCollection() {
      return this.scene.action.config.type === 'collection';
    },
  },

  methods: {
    onVideoPlay() {
      this.$emit('video-play', this.scene.id);
    },
    onVideoPause() {
      this.$emit('video-pause', this.scene.id);
    },
    onUnmute() {
      this.$emit('unmute');
    },
    onMute() {
      this.$emit('mute');
    },
    onGoToCart() {
      console.log('ShopifyItemStory: onGoToCart');
      this.$emit('goToCart');
    },
    nextStory() {
      this.$emit('next-story');
    },
    previousStory() {
      this.$emit('previous-story');
    },
    onCartClose() {
      this.$emit('closeCart');
    }
  },
}
</script>
