<template>
  <div class="image-carousel-wrapper">
    <div class="image-carousel" ref="image-carousel">
      <div class="carousel-container">
        <div v-for="(image, index) in images" :key="index" class="carousel-item" :style="{width: `${carouselImageSize}px`, height: `${carouselImageSize}px`, marginRight: `${gapBetweenSlides}px` }">
          <div class="image-wrapper">
            <img :src="image.src" :alt="image.altText">
          </div>
        </div>
      </div>
    </div>

    <div v-if="showCarouselButtons" style="margin-top: 12px; display: flex; justify-content: center">
      <button @click="prevSlide" style="margin-right: 20px">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 16px; height: 24px;">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"/>
        </svg>
      </button>

      <button @click="nextSlide">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 16px; height: 24px;">
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'ShopifyProductImageCarousel',
  props: {
    images: {
      type: Array,
      required: true
    },
    isWindowMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      windowWidth: 'util/windowWidth',
    }),
    showCarouselButtons() {
      return this.images.length > 1;
    },
    gapBetweenSlides() {
      if (this.windowWidth > 1024) {
        return 8;
      }

      if (this.windowWidth > 590) {
        return 20;
      }

      return 8;

    },
    carouselImageSize() {
      if (this.isWindowMode) {
        if (this.windowWidth > 400) {
          return 282;
        }

        return 200;
      }

      if (this.windowWidth > 1024) {
        return 300;
      }

      if (this.windowWidth > 590) {
        return 450;
      }

      if (this.windowWidth > 400) {
        return 282;
      }

      return 200;
    },
  },
  methods: {
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
      this.scrollToCurrentIndex();
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      this.scrollToCurrentIndex();
    },
    scrollToCurrentIndex() {
      const scrollX = (this.carouselImageSize + this.gapBetweenSlides) * this.currentIndex;
      this.$refs['image-carousel'].scrollTo({left: scrollX, behavior: 'smooth'});
    }
  }
}
</script>
