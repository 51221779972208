<template>
  <div class="shopify-product-mobile-story">
    <!-- Shopify Product -->
    <div class="shopify-product-wrapper">

      <div class="shopify-product-header">
        <!-- Close product card button -->
        <button @click="closeProductCard" @touchstart="(e) => { e.preventDefault(); closeProductCard(); }" class="close-popup-button">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 23px; height: 23px; padding: 5px;">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12"/>
          </svg>
        </button>

        <!-- Back to collection button -->
        <button v-if="withBackArrow" @click="handleBackClick" @touchstart="(e) => { e.preventDefault(); handleBackClick(); }" class="back-to-collection-button">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 23px; height: 23px; padding: 5px;">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
          </svg>
        </button>

        <div class="pricing-wrapper">
          <div class="pricing" >
            <p class="discounted-price">{{ (selectedVariant.priceV2.amount * productQuantity).toFixed(2) }} {{ selectedVariant.priceV2.currencyCode }}</p>
            <p class="price" v-if="getLargestCompareAtPrice(product.variants.nodes, product.priceRange.minVariantPrice.amount * productQuantity, product.priceRange.minVariantPrice.currencyCode, productQuantity)">{{ getLargestCompareAtPrice(product.variants.nodes, product.priceRange.minVariantPrice.amount * productQuantity, product.priceRange.minVariantPrice.currencyCode, productQuantity) }}</p>
          </div>

          <!-- Title -->
          <h3 class="product-title">{{ product.title }}</h3>
        </div>
      </div>

      <!-- Image -->
      <ShopifyProductImageCarousel :images="product.images.nodes" :is-window-mode="isWindowMode" />

      <!-- Options -->
      <div v-if="product.options && product.options.length > 0" style="position: relative">
        <div v-for="(option, optionIndex) in getFilteredProductOptions(product.options)" :key="option.id">
          <div v-if="option.name === 'Title' && option.values && option.values.length === 1 && option.values[0] === 'Default Title'">
          </div>
          <div v-else>
            <h4 class="product-text" :style="{ marginTop: optionIndex === 0 ? `0px` : `20px`, fontWeight: 700, marginBottom: '4px' }">{{ option.name }}</h4>
            <VueSelect
              class="options-select"
              :value="optionsOrder[optionIndex]"
              :options="option.values"
              @input="(value) => handleSelectedOptionChange(value, optionIndex)"
              :searchable="false"
            />
          </div>
        </div>
      </div>

      <!-- Quantity -->
<!--      <h4 class="product-text" style="position: relative; font-weight: 700; margin-top: 20px; margin-bottom: 4px;">Quantity</h4>-->

      <div v-if="selectedVariant.quantityAvailable === 0">
        <p class="product-text" style="color: rgb(107, 114, 128)">Out of stock</p>
      </div>

      <div v-if="selectedVariant.quantityAvailable === 1" style="margin-top: 12px">
        <p class="product-text">Only 1 left in stock</p>
      </div>

<!--      <div class="quantity-input-wrapper" v-else-if="selectedVariant.quantityAvailable > 1">-->
<!--        <button @click="decrementQuantity()" type="button" class="decrementQuantityButton">-->
<!--          <svg style="width: 12px; height: 12px; color: rgb(17, 24, 39)" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">-->
<!--            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>-->
<!--          </svg>-->
<!--        </button>-->

<!--        <input-->
<!--          type="number"-->
<!--          class="quantity-input"-->
<!--          v-model="productQuantity"-->
<!--          disabled-->
<!--        >-->

<!--        <button @click="incrementQuantity()" type="button" class="incrementQuantityButton">-->
<!--          <svg style="width: 12px; height: 12px; color: rgb(17, 24, 39)" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">-->
<!--            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"/>-->
<!--          </svg>-->
<!--        </button>-->
<!--      </div>-->

      <div class="action-button-wrapper" :class="[selectedVariant.quantityAvailable === 1 ? 'lastItemInStock' : '']">
        <div>
          <button v-if="isActionButtonLoading" class="cta-button button-loading">
            <div class="circle-loader"></div>
          </button>

          <template v-else>
            <button
              v-if="isAvailableForSale"
              type="button"
              @click="handleActionButtonClick"
              @touchstart="(e) => { e.preventDefault(); handleActionButtonClick(); }"
              class="cta-button"
            >
              {{ actionButtonText }}
            </button>

            <button
              v-else
              type="button"
              :disabled="true"
              class="cta-button button-disabled"
            >
              {{ actionButtonText }}
            </button>
          </template>
        </div>

        <button @click="openProductPage" @touchstart="(e) => { e.preventDefault(); openProductPage(); }" class="learn-more-button">Learn more</button>
      </div>


      <!-- Description -->
      <div v-if="product.description && product.description.length > 0">
        <h4 class="product-text" style="font-weight: 700; margin-top: 20px; margin-bottom: 4px;">Description</h4>
        <p class="product-text">{{ product.description }}</p>
      </div>
    </div>

    <!-- Buttons -->
    <div class="cart-summary" v-if="itemsCount > 0">
      <div class="wrapper">
        <div>
          <span class="title">My Cart</span>
          <span class="items-count">{{ itemsCount }} {{ itemsCount === 1 ? 'Item' : 'Items' }}</span>
        </div>

        <div class="right" @click="goToCart" @touchstart="(e) => { e.preventDefault(); goToCart(); }">
          <div class="center-vertically" style="margin-right: 8px;">
            <svg class="cart-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
            </svg>
          </div>

          <div class="center-vertically">
            <span class="title">View cart</span>
          </div>
        </div>
      </div>

      <button class="cta-button" v-if="isCheckoutButtonLoading">
        <div class="circle-loader"></div>
      </button>
      <button class="cta-button" @click="goToCheckout" @touchstart="(e) => { e.preventDefault(); goToCheckout(); }" v-else>Go to Checkout</button>
    </div>
  </div>
</template>

<script>
import VueSelect from 'vue-select';
import storefront from '@/api/repositories/storefront';
import {objectToQueryParams} from '@/helpers/object';
import {mapGetters} from 'vuex';
import ShopifyProductImageCarousel from '@/components/ShopifyProductImageCarousel.vue';
import {getLargestCompareAtPrice} from '@/mixins/getLargestCompareAtPrice';
import {createOptionsKey, parseNumericValueFromVariantId, updateCartBubbleForStore} from '@/helpers/cart';

export default {
  name: 'ShopifyProductMobileStory',
  components: {ShopifyProductImageCarousel, VueSelect},
  mixins: [getLargestCompareAtPrice],
  computed: {
    ...mapGetters({
      storefrontAccessToken: 'journeys/storefrontAccessToken',
      storeDomain: 'journeys/storeDomain',
      items: 'cart/items',
      itemsCount: 'cart/itemsCount',
    }),
    actionButtonText() {
      if (this.wasAddedToCart) {
        return 'Added';
      }

      return this.scene.action.label;
    },
    isAvailableForSale() {
      return this.selectedVariant.availableForSale && this.selectedVariant.quantityAvailable > 0;
    },
    selectedVariantKey() {
      if (this.optionsOrder.length === 0) {
        return null;
      }

      let key = '';

      Object.keys(this.optionsOrder).sort().forEach(index => {
        const optionValue = this.optionsOrder[index];

        key += optionValue + ' / ';
      });

      return key.slice(0, -3);

    },
    selectedVariant() {
      if (!this.product.variants || !this.product.variants.nodes || this.product.variants.nodes.length === 0) {
        return null;
      }

      return this.product.variants.nodes.find(node => node.title === this.selectedVariantKey);
    },
    selectedVariantId() {
      if (!this.selectedVariant) {
        return null;
      }

      let variantId = null;

      try {
        variantId = this.selectedVariant.id.split('ProductVariant')[1].substring(1);
      } catch (e) {
        console.error(e);
      }

      return variantId;
    }
  },
  props: {
    scene: {
      type: Object,
      required: true
    },
    journey: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    withBackArrow: {
      type: Boolean,
      default: false,
      required: false
    },
    isWindowMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      productQuantity: 1,
      selectedOptions: {},
      optionsOrder: {},

      startY: 0,
      lastY: 0,
      translateY: 0,

      isActionButtonLoading: false,
      isCheckoutButtonLoading: false,
      wasAddedToCart: false,
    }
  },
  created() {
    this.initProductOptions();
  },
  methods: {
    initProductOptions() {
      const optionsOrder = {};

      this.product.options.forEach((option, index) => {
        optionsOrder[index] = option.values[0];
      });

      this.optionsOrder = optionsOrder;
    },
    // decrementQuantity() {
    //   if (this.productQuantity === 1) {
    //     return;
    //   }
    //
    //   this.productQuantity = this.productQuantity - 1;
    // },
    // incrementQuantity() {
    //   if (this.productQuantity === this.selectedVariant.quantityAvailable) {
    //     return;
    //   }
    //
    //   this.productQuantity = this.productQuantity + 1;
    // },
    openProductPage() {
      const variantId = this.selectedVariantId;

      if (variantId !== null) {
        console.log('openProductPage()', { url: `https://${this.storeDomain}/products/${this.product.handle}?variant=${variantId}`, variantId });
        window.location.href = `https://${this.storeDomain}/products/${this.product.handle}?variant=${variantId}`;
        // window.open(`https://${this.storeDomain}/products/${this.product.handle}?variant=${variantId}`, '_blank');
        return;
      }

      console.log('openProductPage()', { url: `https://${this.storeDomain}/products/${this.product.handle}`, variantId });
      window.location.href = `https://${this.storeDomain}/products/${this.product.handle}`;
      // window.open(`https://${this.storeDomain}/products/${this.product.handle}`, '_blank');
    },
    handleSelectedOptionChange(newValue, optionIndex) {
      const optionsOrder = {...this.optionsOrder};

      optionsOrder[optionIndex] = newValue;

      this.optionsOrder = optionsOrder;
    },
    async handleActionButtonClick() {
      if (!this.selectedVariantId) {
        return;
      }

      this.isActionButtonLoading = true;

      const actionType = this.scene.action.type;
      const customCartAttributes = this.getCustomCartAttributes(actionType);

      if (actionType !== 'CHECKOUT') {
        try {
          await storefront.setCartAttributes(customCartAttributes);
        } catch (e) {
          console.error(e);
        }
      }

      if (actionType === 'ADD_TO_CART') {
        try {
          await storefront.addToCart({
            id: this.selectedVariantId,
            quantity: this.productQuantity,
          });

          await this.addToReelTokCart();

          document.documentElement.dispatchEvent(new CustomEvent('cart:refresh', {
            bubbles: true,
          }));

          const { data } = await storefront.getCart();

          const cartItemsCount = data.item_count;
          const storeDomain = window.location.hostname;

          updateCartBubbleForStore(storeDomain, cartItemsCount);

          this.wasAddedToCart = true;
        } catch (e) {
          console.error(e);
        } finally {
          this.isActionButtonLoading = false;
        }
      }

      if (actionType === 'DIRECT_TO_CART') {
        await storefront.addToCart({
          id: this.selectedVariantId,
          quantity: this.productQuantity,
        });

        window.location.href = `https://${this.storeDomain}/cart`;
        return;
      }

      if (actionType === 'CHECKOUT') {
        const queryParams = objectToQueryParams(customCartAttributes);
        window.location.href = `https://${this.storeDomain}/cart/${this.selectedVariantId}:${this.productQuantity}?${queryParams}`;
      }
    },
    async addToReelTokCart() {
      const options = {};

      this.product.options.forEach((option, optionIndex) => {
        if (option.name === 'Title' && option.values && option.values.length === 1 && option.values[0] === 'Default Title') {
          return;
        }

        options[option.name] = this.optionsOrder[optionIndex];
      });

      const product = {
        title: this.product.title,
        image: this.product.images.nodes[0].src,
        quantity: this.productQuantity,
        selectedVariant: this.selectedVariant,
        options,
        optionsKey: createOptionsKey(options), // need options key to know when to increment quantity and when to add new product
      };

      await this.$store.dispatch('cart/addItem', product);
    },
    getCustomCartAttributes(actionType) {
      const attributes = {};
      const journeyUuid = this.journey.uuid;
      const sceneUuid = this.scene.uuid;

      let event = 'ADD_TO_CART';

      if (actionType === 'DIRECT_TO_CART') {
        event = 'GO_TO_CART';
      }

      if (actionType === 'CHECKOUT') {
        event = 'GO_TO_CHECKOUT';
      }

      attributes[`REEL_TOKK_JOURNEY_${journeyUuid}`] = journeyUuid;
      attributes[`REEL_TOKK_SCENE_${sceneUuid}`] = sceneUuid;
      attributes[`REEL_TOKK_EVENT_ADDED_TO_CART`] = 'ADDED_TO_CART';
      attributes[`REEL_TOKK_EVENT_${event}`] = event;

      return attributes;
    },
    getFilteredProductOptions(options) {
      return options.filter(option => {
        return !(option.name === 'Title' && option.values.length === 1 && option.values[0] === 'Default Title');
      });
    },
    closeProductCard() {
      this.$emit('closeShopifyItemCard');
    },
    handleBackClick() {
      this.$emit('backClick');
    },
    goToCart() {
      this.$emit('goToCart');
    },
    goToCheckout() {
      try {
        this.isCheckoutButtonLoading = true;

        const journeyUuid = this.journey.uuid;
        const sceneUuid = this.scene.uuid;

        const customCartAttributes = {};
        customCartAttributes[`REEL_TOKK_JOURNEY_${journeyUuid}`] = journeyUuid;
        customCartAttributes[`REEL_TOKK_SCENE_${sceneUuid}`] = sceneUuid;
        customCartAttributes[`REEL_TOKK_EVENT_ADDED_TO_CART`] = 'ADDED_TO_CART';
        customCartAttributes[`REEL_TOKK_EVENT_GO_TO_CHECKOUT`] = 'GO_TO_CHECKOUT';

        const variantToQuantityMapString = this.items.map(item => {
          const selectedVariantId = parseNumericValueFromVariantId(item.selectedVariant.id);
          return `${selectedVariantId}:${item.quantity}`;
        }).join(',');

        const queryParams = objectToQueryParams(customCartAttributes);

        window.location.href = `https://${this.storeDomain}/cart/${variantToQuantityMapString}?${queryParams}`;
      } catch (e) {
        console.error(e);
      } finally {
        this.isCheckoutButtonLoading = false;
      }
    }
  },
}
</script>
