<template>
  <div class="shopify-item-mobile-story">
    <div class="product-card" @click="openProductCard" @touchstart="(e) => { e.preventDefault(); openProductCard(); }">
      <img
        v-if="shopifyItem.featuredImage || shopifyItem.image"
        :src="shopifyItem.featuredImage ? shopifyItem.featuredImage.src : (shopifyItem.image ? shopifyItem.image.src : '')"
        :alt="shopifyItem.featuredImage ? shopifyItem.featuredImage.altText : (shopifyItem.image ? shopifyItem.image.altText : '')"
      >

      <div style="display: flex; justify-content: space-between; width: 100%;">
        <div style="display: flex; flex-direction: column; justify-content: center; color: #FFFFFF; flex-grow: 1; padding-left: 12px; padding-right: 12px; ">
          <p class="item-title">{{ shopifyItem.title.length > 29 ? shopifyItem.title.slice(0, 29) + '...' : shopifyItem.title }}</p>
          <div style="display: flex">
            <p class="item-price">{{ getProductPrice() }}</p>

<!--            <div v-if="showSaleBadge()" style="margin-left: 8px; display: flex; flex-direction: column; justify-content: center;">-->
<!--              <div class="sale-badge">Sale</div>-->
<!--            </div>-->
          </div>
        </div>

        <div class="shop-button-wrapper">
          <button class="shop-button">Shop</button>
        </div>
      </div>
    </div>

    <div ref="popup" v-show="isShopifyItemCardOpen || isCartOpen" class="popup-wrapper">
      <div class="popup">
        <MobileCart @closeCart="onCartClose" v-if="isCartOpen" />

        <template v-else>
          <ShopifyProductMobileStory
            v-if="isShopifyProduct"
            @closeShopifyItemCard="closeShopifyItemCard"
            :scene="scene"
            :journey="journey"
            :product="shopifyItem"
            @goToCart="onGoToCart"
            :is-window-mode="isWindowMode"
          />

          <ShopifyCollectionMobileStory
            v-else-if="isShopifyCollection"
            @closeShopifyItemCard="closeShopifyItemCard"
            @goToCart="onGoToCart"
            :scene="scene"
            :journey="journey"
            :collection="shopifyItem"
            :is-window-mode="isWindowMode"
          />
        </template>
      </div>
    </div>

    <VideoPlayer
      :scene="scene"
      @sceneClick="onSceneClick"
      @pause="onVideoPause"
      @play="onVideoPlay"
      @unmute="onUnmute"
      @mute="onMute"
      @close="onClose"
      @goToPrevScene="onPrevSceneClick"
      @goToNextScene="onNextSceneClick"
      @goToCart="onGoToCart"
      class="video-player-mobile"
      :is-playing="isPlaying"
      :is-muted="isMuted"
      :cloudName="cloudName"
      :publicId="scene.video.public_id"
      :playerConfig="{
        loop: true,
        muted: true,
        controls: false,
        bigPlayButton: false,
        responsive: true,
        aspectRatio: '9:16',
        fluid: true,
      }"
      :sourceConfig="{}"
      :show-watermark="showWatermark"
      :show-mobile-scene-controls="showMobileSceneControls"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ShopifyProductMobileStory from '@/components/stories/Shopify/mobile/ShopifyProductMobileStory.vue';
import ShopifyCollectionMobileStory from '@/components/stories/Shopify/mobile/ShopifyCollectionMobileStory.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import MobileCart from '@/components/MobileCart.vue';

export default {
  name: 'ShopifyItemMobileStory',
  components: {MobileCart, ShopifyCollectionMobileStory, VideoPlayer, ShopifyProductMobileStory},
  computed: {
    ...mapGetters({
      cloudName: 'journeys/cloudName',
      storefrontAccessToken: 'journeys/storefrontAccessToken',
      storeDomain: 'journeys/storeDomain',
    }),
    isShopifyProduct() {
      return this.scene.action.config.type === 'product';
    },
    isShopifyCollection() {
      return this.scene.action.config.type === 'collection';
    },
  },
  data() {
    return {
      isShopifyItemCardOpen: false,
    }
  },
  props: {
    scene: {
      type: Object,
      required: true
    },
    journey: {
      type: Object,
      required: true
    },
    shopifyItem: {
      type: Object,
      required: true
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    isMuted: {
      type: Boolean,
      default: false
    },
    showWatermark: Boolean,
    showMobileSceneControls: {
      type: Boolean,
      default: false
    },
    isCartOpen: {
      type: Boolean,
      default: false
    },
    isWindowMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCartClose() {
      this.$emit('closeCart');
    },
    openProductCard() {
      this.isShopifyItemCardOpen = true;
      this.$store.dispatch('util/productPopupOpened', true);
    },
    closeShopifyItemCard() {
      this.isShopifyItemCardOpen = null;
      this.$store.dispatch('util/productPopupOpened', false);
    },
    onVideoPlay() {
      this.$emit('video-play', this.scene.id);
    },
    onSceneClick(scene) {
      this.$emit('scene-click', scene);
    },
    onVideoPause() {
      this.$emit('video-pause', this.scene.id);
    },
    onUnmute() {
      this.$emit('unmute');
    },
    onMute() {
      this.$emit('mute');
    },
    onClose() {
      this.$emit('close');
    },
    onPrevSceneClick() {
      this.$emit('goToPrevScene');
    },
    onNextSceneClick() {
      this.$emit('goToNextScene');
    },
    onGoToCart() {
      this.$emit('goToCart');
    },
    getProductPrice() {
      if (this.isShopifyCollection) {
        return this.getPriceForCollection();
      }

      if (!this.shopifyItem.priceRange || !this.shopifyItem.priceRange.minVariantPrice) {
        return null;
      }

      const currency = this.shopifyItem.priceRange.minVariantPrice.currencyCode.toLowerCase();
      const price = this.shopifyItem.priceRange.minVariantPrice.amount;

      if (['usd', 'aud', 'cad', 'nzd', ].includes(currency)) {
        return `$${price}`;
      }

      return `${price} ${currency}`;
    },
    showSaleBadge() {
      if (this.isShopifyCollection) {
        return this.showSaleBadgeForCollection(this.shopifyItem);
      }

      return this.showSaleBadgeForProduct(this.shopifyItem);
    },
    showSaleBadgeForCollection(collection) {
      if (!collection?.products?.nodes || collection?.products?.nodes.length === 0) {
        return false;
      }

      for (const product of collection.products.nodes) {
        if (this.showSaleBadgeForProduct(product)) {
          return true;
        }
      }

      return false;
    },
    showSaleBadgeForProduct(item) {
      if (!item?.variants?.nodes || item?.variants?.nodes.length === 0) {
        return false;
      }

      if (!item.priceRange || !item.priceRange.minVariantPrice) {
        return false;
      }

      const currentPrice = item.priceRange.minVariantPrice.amount;

      let biggestVariantPrice = 0;

      item.variants.nodes.forEach(node => {
        if (node.compareAtPrice && node.compareAtPrice.amount) {
          const variantPrice = parseFloat(node.compareAtPrice.amount);

          if (variantPrice > biggestVariantPrice) {
            biggestVariantPrice = variantPrice;
          }
        }
      });

      if (biggestVariantPrice === 0) {
        return false;
      }

      return biggestVariantPrice > parseFloat(currentPrice);
    },
    getPriceForCollection() {
      const products = this.shopifyItem.products.nodes;

      if (products.length === 0) {
        return null;
      }

      const currency = products[0].priceRange.minVariantPrice.currencyCode.toLowerCase();
      const collectionProductPrices = products.map(product => parseFloat(product.priceRange.minVariantPrice.amount));

      collectionProductPrices.sort((a, b) => a - b);

      const price = collectionProductPrices[0];

      if (['usd', 'aud', 'cad', 'nzd', ].includes(currency)) {
        return `From: $${price}`;
      }

      return `From: ${price} ${currency}`;
    },
  }
}
</script>
