<template>
  <div class="home">
    <StoryViewer
      v-if="isStoryViewerOpen"
      :journey="selectedJourney"
      :selected-scene-index="selectedJourneySceneIndex"
      @close="closeStoryViewer"
      @story-changed="handleOnStoryChanged"
    />

    <WindowedStoryViewer
      v-if="isWindowedStoryViewerOpen"
      :journey="selectedJourney"
      :selected-scene-index="selectedJourneySceneIndex"
      @close="closeStoryViewer"
      @story-changed="handleOnStoryChanged"
      :portal-key="portalKey"
    />

    <div v-show="!isStoryViewerOpen && showJourney" style="width: 100%; height: 100%;">
      <StoryFormat v-if="journey.type === 'STORY'" :journey="journey" @click="handleOnJourneyClick" />
      <CarouselFormat v-if="journey.type === 'CAROUSEL'" :journey="journey" @click="handleOnJourneyClick" />
      <FloatingFormat v-if="isFloatingFormat" :journey="journey" @click="handleOnJourneyClick" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import StoryFormat from '@/components/formats/StoryFormat.vue';
import CarouselFormat from '@/components/formats/CarouselFormat.vue';
import FloatingFormat from '@/components/formats/FloatingFormat.vue';
import StoryViewer from '@/components/StoryViewer.vue';
import repository from '@/api/repositories/repository';
import WindowedStoryViewer from '@/components/WindowedStoryViewer.vue';

export default {
  name: 'HomeIndex',
  components: {WindowedStoryViewer, StoryViewer, StoryFormat, CarouselFormat, FloatingFormat},
  data() {
    return {
      portalKey: Date.now(),
      selectedJourney: null,
      selectedJourneySceneIndex: 0,

      isStoryViewerOpen: false,
      isWindowedStoryViewerOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      journey: 'journeys/journey',
      storefrontAccessToken: 'journeys/storefrontAccessToken',
      storeDomain: 'journeys/storeDomain',
    }),
    // showStoryViewer() {
    //   return this.selectedJourney !== null && !this.showWindowedStoryViewer;
    // },
    showJourney() {
      return this.journey !== null;
    },
    isFloatingFormat() {
      if (this.journey === null) {
        return false;
      }

      const floatingTypes = [
        'FLOATING_TOP_LEFT',
        'FLOATING_TOP_RIGHT',
        'FLOATING_BOTTOM_LEFT',
        'FLOATING_BOTTOM_RIGHT'
      ];

      return floatingTypes.includes(this.journey.type);
    },
    // showWindowedStoryViewer() {
    //   return this.selectedJourney !== null && this.isWindowedMode;
    // },
    isWindowedMode() {
      return this.journey.config.windowed_mode;
    }
  },
  created() {
    try {
      const cart = JSON.parse(localStorage.getItem('reeltok_cart') || '{}');

      if (cart.items) {
        this.$store.commit('cart/SET_ITEMS', cart.items);
      }

      if (this.isFloatingFormat) {
        const sceneUuids = this.journey.scenes.map(scene => scene.uuid);
        repository.trackImpressions(this.journey.uuid, this.storefrontAccessToken, [sceneUuids[0]]);
        return;
      }
      const sceneUuids = this.journey.scenes.map(scene => scene.uuid);

      repository.trackImpressions(this.journey.uuid, this.storefrontAccessToken, sceneUuids);
    } catch (error) {
      console.error(error);
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);

    const urlContainsScene = 'reeltok_scene' in this.$route.query;

    if (!urlContainsScene) {
      return;
    }

    const reeltokScene = this.$route.query.reeltok_scene;

    const reeltokSceneIndex = this.journey.scenes.findIndex(scene => scene.uuid === reeltokScene);

    if (reeltokSceneIndex === -1) {
      return;
    }

    this.selectedJourneySceneIndex = reeltokSceneIndex;
    this.selectedJourney = this.journey;
    this.$store.dispatch('util/setLandedDirectToScene', true);

    if (this.isWindowedMode) {
      this.isWindowedStoryViewerOpen = true;
      this.portalKey = Date.now();
    } else {
      this.isStoryViewerOpen = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.$store.dispatch('util/setWindowWidth', window.innerWidth);
    },
    handleOnJourneyClick({journey, sceneId, sceneUuid}) {
      this.portalKey = Date.now();
      this.selectedJourneySceneIndex = journey.scenes.findIndex(scene => scene.id === sceneId) ?? 0;
      this.selectedJourney = journey;
      repository.trackImpressions(journey.uuid, this.storefrontAccessToken, [sceneUuid]);
      repository.trackClick(sceneUuid);
      this.$store.dispatch('cart/setJourneyAndScene', { journeyUuid: journey.uuid, sceneUuid });

      if (this.isWindowedMode) {
        this.isWindowedStoryViewerOpen = true;
      } else {
        this.isStoryViewerOpen = true;
      }
    },
    closeStoryViewer() {
      this.selectedJourney = null;
      this.isWindowedStoryViewerOpen = false;
      this.isStoryViewerOpen = false;
    },
    handleOnStoryChanged({ sceneUuid }) {
      repository.trackImpressions(this.journey.uuid, this.storefrontAccessToken, [sceneUuid]);
    }
  },
}
</script>
