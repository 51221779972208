export const getLargestCompareAtPrice = {
  methods: {
    getLargestCompareAtPrice(nodes, currentPrice, currencyCode, productQuantity = 1) {
      if (nodes.length === 0) {
        return null;
      }

      let biggestVariantPrice = 0;

      nodes.forEach(node => {
        if (node.compareAtPrice && node.compareAtPrice.amount) {
          const variantPrice = parseFloat(node.compareAtPrice.amount);

          if (variantPrice > biggestVariantPrice) {
            biggestVariantPrice = variantPrice;
          }
        }
      });

      biggestVariantPrice = biggestVariantPrice * productQuantity;

      if (biggestVariantPrice === 0) {
        return null;
      }

      if (biggestVariantPrice <= parseFloat(currentPrice)) {
        return null;
      }

      return biggestVariantPrice.toFixed(2) + ' ' + currencyCode;
    },
  }
};