<template>
  <div>
    <ShopifyItemStory
      v-if="[
        'ADD_TO_CART',
        'DIRECT_TO_CART',
        'CHECKOUT',
      ].includes(scene.action.type)"
      @video-play="onVideoPlay"
      @video-pause="onVideoPause"
      @mute="onMute"
      @unmute="onUnmute"
      @next-story="nextStory"
      @previous-story="previousStory"
      @goToCart="onGoToCart"
      @closeCart="onCartClose"
      :is-playing="isPlaying"
      :is-muted="isMuted"
      :scene="scene"
      :journey="journey"
      :shopify-item="shopifyItem"
      :has-controls="hasControls"
      :is-cart-open="isCartOpen"
    />
    <SimpleButtonStory
      v-else
      @video-play="onVideoPlay"
      @video-pause="onVideoPause"
      @mute="onMute"
      @unmute="onUnmute"
      @next-story="nextStory"
      @previous-story="previousStory"
      @goToCart="onGoToCart"
      @closeCart="onCartClose"
      @close="onClose"
      :is-playing="isPlaying"
      :is-muted="isMuted"
      :scene="scene"
      :journey="journey"
      :has-controls="hasControls"
      :is-cart-open="isCartOpen"
    />
  </div>
</template>

<script>
import ShopifyItemStory from '@/components/stories/Shopify/desktop/ShopifyItemStory.vue';
import SimpleButtonStory from '@/components/stories/Simple/desktop/SimpleButtonStory.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';

export default {
  name: 'DesktopStory',
  components: {VideoPlayer, SimpleButtonStory, ShopifyItemStory },
  props: {
    scene: {
      type: Object,
      required: true
    },
    journey: {
      type: Object,
      required: true
    },
    shopifyItem: {
      type: Object,
      required: true
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    isMuted: {
      type: Boolean,
      default: false
    },
    hasControls: {
      type: Boolean,
    },
    isCartOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    nextStory() {
      this.$emit('next-story');
    },
    previousStory() {
      this.$emit('previous-story');
    },
    onVideoPlay(sceneId) {
      this.$emit('video-play', sceneId);
    },
    onVideoPause(sceneId) {
      this.$emit('video-pause', sceneId);
    },
    onUnmute() {
      this.$emit('unmute');
    },
    onMute() {
      this.$emit('mute');
    },
    onGoToCart() {
      this.$emit('goToCart');
    },
    onCartClose() {
      this.$emit('closeCart');
    },
    onClose() {
      this.$emit('close');
    }
  }
}
</script>
